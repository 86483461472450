<template>
    <div class="box">
        <div class="header-box">
            <el-form v-loading="loading" :inline="true" ref='EnForm' :model="EnForm" class="demo-form-inline" :rules="rulesEn">
                <el-form-item label="日期" required>
                    <el-date-picker
                    v-model="EnForm.date"
                    type="date"
                    placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="入库编号" required prop='en_id'>
                    <el-input v-model="EnForm.en_id" placeholder="请输入编号"></el-input>
                </el-form-item>
                <el-form-item label="仓库" required prop="wid">
                    <el-select v-model="EnForm.wid" 
                    :remote-method="searchWarehouse"
                    remote
                    filterable placeholder="请选择目标仓库">
                        <el-option
                        v-for="item in warehouseList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="来往单位" required prop="related_party">
                    <el-select v-model="EnForm.related_party" 
                    :remote-method="searchRelatedParty"
                    remote
                    filterable placeholder="请选择来往单位">
                        <el-option
                        v-for="item in relatedList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="备注" v-if="!showAddRemark">
                    <i class="el-icon-plus addRemark" title="添加" @click="showRemark" />
                </el-form-item> -->
                <el-form-item label="备注" v-if="showAddRemark">
                    <el-input maxlength="100" v-model="EnForm.remark" placeholder="备注内容"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="list-box">
            <el-empty v-if="list.length<=0" description="还没有入库数据"></el-empty>
       
            <!-- table -->
            <el-table
            v-loading="loading"
            v-if="list.length>=1" 
            max-height="550"
            show-summary
            border
            :data="list"
            style="width: 100%">
                <el-table-column
                    prop="num"
                    label="编号">
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="物品名称">
                </el-table-column>
                <el-table-column
                    prop="number"
                    label="数量">
                    <template slot-scope="scope">
                        {{scope.row.number +' ✖️ '+ scope.row.specification_unit+''}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="specification_number"
                    label="规格数量">
                    <template slot-scope="scope">
                        {{scope.row.specification_number +' ('+ scope.row.unit+')'}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="specification——money"
                    label="单价 (元)">
                    <template slot-scope="scope">
                        {{scope.row.specification_money + ' 元/'+scope.row.specification_unit}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="total"
                    label="总价 (元)">
                </el-table-column>
                <el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                        </template>
                        </el-table-column>
                </el-table-column>
            </el-table>
        </div>
        <div class="addForm">
            <el-form v-loading="loading" :inline="true" ref='form' :model="form" class="demo-form-inline" :rules="rules" required>
                <el-form-item label="物品名称" required prop="num">
                    <el-select v-model="form.num" 
                    :remote-method="searchGoods"
                    @change="changeGods"
                    remote
                    filterable placeholder="请选择物品名称" >
                        <el-option
                        v-for="item in goods"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item >
                    <div style="width:80px">
                        <el-select size="mini" @change="change"
                        v-model="form.specification_unit" placeholder="单位">
                        <el-option
                            v-for="item in unitList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item :label="'数量 ('+(form.specification_unit||'')+')'" prop="number" required>
                    <el-input-number @change="change" v-model="form.number" :min="1" label="入库数量"></el-input-number>
                </el-form-item>
                <el-form-item label="单价" required>
                    <el-input-number :disabled="unitList.length>1?true:false" @change="change" :precision="2" v-model="form.specification_money" label="单价"></el-input-number>
                </el-form-item>
                <el-form-item>
                    <el-button icon="el-icon-upload2" type="success" @click="saveToList">添加</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 保存入库 -->
        <div class="btn-box">
            <el-button :disabled="loading" type="primary" @click="saveEN">保存数据</el-button>
        </div>
    </div>
</template>
<script>
import {formatTime} from '@/utils/util.js'
import {accMul} from '@/utils/caculate.js' 
export default {
    props:{
        en_id:{//更新的入库单id
            type:String,
            value:''
        }
    },
    watch:{
        en_id:{
            immediate: true,
            handler(en_id){
                //重置表单
                //this.$refs['form'].resetFields()
                if(en_id!=""){
                    //如果
                    this.updateId = en_id
                    //获取数据
                    this.getEnteringDetail()
                }
            },
            deep:true
        }
    },
    data(){
        var checkEnId = (rule, value, callback) => {
            let reg =  /.*[\u4e00-\u9fa5]+.*$/
            if (value === '') {
                callback(new Error('请输入入库编号'));
            } else if(reg.test(value)){
                callback(new Error('编号不能包含中文'));
            }
            callback();
        };
        return{
            user:{},//当前用户
            updateId:'',
            unitList:[],//单位选择
            showAddRemark:false,//显示备注
            list:[],//入库的列表
            warehouseList:[],//搜索仓库列表
            relatedList:[],//搜索来往单位
            EnForm:{
                en_id:'RK_'+new Date().getTime(),//入库单编号
                wid:'',//仓库id
                date:new Date(),//日期
                related_party:'',//往来单位
                money:0,//总价
                remark:'',//备注
            },
            form:{
                num:'',//物品编号
                name:'',//物品名称
                unit:'',//物品单位
                number:1,//未转换规格的数量
                specification_number:0,//转换规格后的数量
                specification_money:0,//转换规格后的价格
                specification_unit:'',//转换规格单位
                money:0,//单价
                total:0,//总价 specification_number*money
            },
            goods:[],//搜索的物品
            rules:{
                num: [
                    { required: true, message: '请选择物品', trigger: 'blur' }
                ],
                wid: [
                    { required: true, message: '请输入数量', trigger: 'blur' }
                ]
            },
            rulesEn:{
                en_id: [
                    { validator: checkEnId, trigger: 'blur' }
                ],
                wid: [
                    { required: true, message: '请选择目标仓库', trigger: 'blur' }
                ],
                related_party:[
                    { required: true, message: '请选择往来单位', trigger: 'blur' }
                ],
            }
        }
    },
    created(){
        this.searchWarehouse('')
        this.searchRelatedParty('')
        try {
          this.user = JSON.parse(sessionStorage.getItem("user"))
         console.log(this.user)  
        } catch (error) {
           console.log(error) 
        }
        
    },
    methods:{
        //获取要更新的内容
        getEnteringDetail(){
            if(!this.updateId||this.updateId==''){
                return
            }
            let params = {
                method:"getEnteringDetail",
                en_id:this.updateId
            }
            this.loading = true
            this.$ajax.post(this.API.api,params).then(({data})=>{
                this.loading = false
                //console.log(data.enData[0].date,'---pppp')
                if(data&&data.enData[0]){
                    this.EnForm = data.enData[0] 
                    this.EnForm.date = new Date(data.enData[0].date.replace(/-/g, "/"))
                    //console.log(this.EnForm,'-----',data.enData[0])
                    this.list  = data.list
                }
            }).catch(err=>{
                console.log(err)
                this.loading = false
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        //删除list某一项
        handleDelete(index,row){
            console.log(index,row)
            this.list.splice(index,1)
        },
        //显示备注
        showRemark(){
            this.showAddRemark = true
        },
        //搜索功能
        searchGoods(key){
            console.log(key)
            let params = {
                method:"getGoods",
                key,
                page:1,
                pageSize:5
            }
            this.$ajax.post(this.API.api,params).then(({data})=>{
                console.log(data)
                if(data){
                    data.list.forEach(item=>{
                        item.label = item.cn_name 
                        item.value = item.num
                    })
                    this.goods = data.list
                }
            }).catch(err=>{
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        //选择了物品后获取其单价
        changeGods(data){
            //console.log(data)
            this.goods.forEach(item=>{
                //console.log(item)
                if(item.num==data){
                    console.log(item)
                    this.form.money = item.price
                    this.form.name = item.cn_name
                    this.form.unit = item.unit
                    this.form.specification_unit = item.unit
                    this.form.specification_money = item.price
                    this.form.specification = item.specification
                    //获取单位选择
                    this.getUnitList(item.specification)
                    
                    //this.form.specification = item.specification
                }
                
            })
        },
        //关闭当前组件窗口
        close(cb){
            if(this.list.length<=0||this.updateId!=''){
                this.resetAll()
                return cb()
            }
            this.$confirm('当前数据未保存，确定要关闭当前页面吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.resetAll()
                    return cb()
            })
        },
        //重置表单
        resetAll(){
            //清空数据
            this.list = []
            this.updateId = ''
                    this.form={
                        num:'',//物品编号
                        name:'',//物品名称
                        unit:'',//物品单位
                        number:1,//未转换规格的数量
                        specification_number:0,//转换规格后的数量
                        specification_money:0,//转换规格后的价格
                        specification_unit:'',//转换规格单位
                        money:0,//单价
                        total:0,//总价 specification_number*money
                    }
                    this.EnForm = {
                        en_id:'RK_'+new Date().getTime(),//入库单编号
                        wid:'',//仓库id
                        date:new Date(),//日期
                        related_party:'',//往来单位
                        money:0,//总价
                        remark:'',//备注
                    }
        },
        //获取单位列表
        getUnitList(specification){
            let arr = specification.split("/")
            if(arr.length<=1){
                console.log(this.form,'form')
                this.unitList = []
                return
            }
            let unitlist=[],current=0
            arr.forEach((item,index)=>{
                let a = item.split("*")
                if(a[1]==this.form.unit){
                    //当前选择的单位
                    current = index
                }
                unitlist.push({
                    scale:a[0],
                    unit:a[1],
                    label:a[1],
                    value:a[1],
                })
            })
            this.unitList = unitlist
            //如果unit单
            console.log(this.unitList)
            if(current==arr.length-1&&arr.length>=2){
                //当前是最小单位，则自动转换规格到上一单位
                this.form.specification_unit = this.unitList[current-1].unit
                this.form.specification_money = this.form.money*this.unitList[current].scale
            }
        },//监听输入数量
        change(){
            this.$forceUpdate()
            let orinigal=this.unitList.length-1,current
            this.unitList.forEach((item,index)=>{
                if(item.unit==this.form.specification_unit){
                    //当前选择的单位，再获取当前为转换前的数量
                    current = index
                }
            })
            console.log(current,'----current')
            if(current||current==0){
                //获取相差倍数
                let scale = 1
                //var i=a.length-1;i>=0;i--
                //for(var i=current;i<orinigal;i++){
                for(var i=orinigal;i>current;i--){
                    scale = scale*this.unitList[i].scale
                    console.log('scale',scale+'*'+this.unitList[i].scale)
                }
                console.log(scale,"scale")
                this.form.specification_number = accMul(scale,this.form.number)//scale * this.form.number
                this.form.specification_money = accMul(scale,this.form.money)//scale * this.form.money 
                console.log(this.form)
            }else{
                this.form.specification_number = this.form.number 
                this.form.money = this.form.specification_money
            }
            
        },
        //提交到列表
        saveToList(){
            console.log(this.form)
            this.$refs['form'].validate((valid) => {
                console.log(valid)
                if(valid){
                    //获取规格转换单位和规格单价
                    //1.将总价计算
                    this.change()
                    this.form.total = this.form.number*this.form.specification_money
                    //添加到list
                    console.log(this.form)
                    this.list.push(this.form)
                    console.log(this.list)
                    //重置表单
                    //this.$refs['form'].resetFields()
                    this.EnForm.money+=this.form.specification_money
                    this.unitList = []
                    this.form={
                        num:'',//物品编号
                        name:'',//物品名称
                        unit:'',//物品单位
                        number:1,//未转换规格的数量
                        specification_number:0,//转换规格后的数量
                        specification_money:0,//转换规格后的价格
                        specification_unit:'',//转换规格单位
                        money:0,//单价
                        total:0,//总价 specification_number*money
                    }
                }
                
            })
        },
        //保存到数据库
        saveEN(){
            this.$refs['EnForm'].validate((valid) => {
                if(valid){
                    // console.log(this.EnForm)
                    // console.log(this.list)
                    if(this.list.length<=0){
                        this.$message({
                            message: '入库列表为空！',
                            type: 'warning'
                        });
                        return
                    }
                    //更新获取总价格
                    let total = 0
                    this.list.forEach(item=>{
                        total += Number(item.total)
                    })
                    //提交数据
                    let params = this.EnForm 
                    params.time = formatTime(params.date)
                    this.EnForm.money = total
                    this.EnForm.originator = this.user.username
                    if(this.updateId&&this.updateId!=''){
                        params.method = "updateEntering"
                        params.updateId = this.updateId
                    }else{
                        params.method = "addEntering"
                    }
                    //params.method = this.updateId&&this.updateId!=''?"updateEntering" :"addEntering"
                    params.list = JSON.stringify(this.list)
                    //console.log(params)
                    this.loading = true
                    this.$ajax.post(this.API.api,params).then(res=>{
                        this.loading = false 
                        //console.log(res)
                        this.$message({
                            message: res.msg||this.CONST.SUC_ADD,
                            type: 'success'
                        });
                        //告诉上一层
                        this.resetAll()
                        this.$emit("addSuc")
                    }).catch(err=>{
                        this.loading = false 
                        console.log(err)
                        console.log(err.data)
                        this.$message({
                            message: err.msg?err.msg:err,
                            type: 'warning'
                        });
                    })

                }
            })
        },
        //搜索仓库
        searchWarehouse(key){
            console.log(key)
            let params = {
                method:"getWarehouse",
                key,
                page:1,
                pageSize:5
            }
            this.loading = true
            this.$ajax.post(this.API.api,params).then(({data})=>{
                this.loading = false 
                console.log(data)
                if(data){
                    data.list.forEach(item=>{
                        item.label = item.name 
                        item.value = item.num
                    })
                    this.warehouseList = data.list
                    //默认为第一个仓库
                    this.EnForm.wid = this.warehouseList[0].value
                }
            }).catch(err=>{
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        //搜索往来单位
        searchRelatedParty(key){
            let params = {
                method: "getRelatedParty",
                page:1,
                pageSize:5,
                name:key
            }
            this.$ajax.post(this.API.api,params).then(({data})=>{
                let list = []
                if(data&&data.list){
                    data.list.forEach(item=>{
                        item.label = item.name 
                        item.value = item.name
                        if(item.type==0){
                            list.push(item)
                        }
                    })
                    this.relatedList = list //data.list
                }

            }).catch(err=>{
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        }
    }
}
</script>
<style lang="less">
.box{
    display: flex;
    flex-direction: column;
}
.addForm{
    border-bottom: 1px #DBDBDB solid;
}
.header-box{
    border-bottom: 1px #DBDBDB solid;
}
.list-box{
    background: #f8f8f8;
    margin: 10px 0;
    min-height: 200px;
}
.addRemark{
    cursor: pointer;
}
.addRemark:hover{
    color: #39f;
}
</style>